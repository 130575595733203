// import {API} from "../../../axios";

import {API} from "@/plugins/axios";
import swal from "sweetalert2";
import {handleRegistrationError} from "@/plugins/store/errorHandlerSwal";

const actions = {
    async getVwelcomeText({commit}) {

        await API.post('/getText',
            {
                text_search: 'vwelcome'
            })
            .then((response) => {
                commit('get_VWelcomeText', response.data.Text);
                // resolve(response)
            })
            .catch(error => {
                console.log(error);
            });
    },

    getShootingbook({commit, rootState, rootGetters}) {
        let URL = '';
        if (rootGetters["UserAuth/isLoggedIn"]) {
            API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
            URL = '/user/shootingbook';
        } else {
            API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
            URL = '/club/shootingbook';
        }
        API.get(URL)
            .then((resp) => {
                const data = resp.data
                commit('get_Shootingbook', data)
            })
            .catch(error => {
                handleRegistrationError(error)
            });
    },
    getShootingbookforApproval({commit, rootState, rootGetters}) {
        let URL = '';
        if (rootGetters["UserAuth/isLoggedIn"]) {
            API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
            URL = '/user/shootingbook/approval/approvals_get';
        } else {
            API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
            URL = '/club/shootingbook/approval/approvals_get';
        }
        API.get(URL)
            .then((resp) => {
                const data = resp.data
                commit('get_ShootingbookApproval', data)
            })
            .catch(error => {
                handleRegistrationError(error)
            });
    },
    async setShootingRecordApproval({dispatch, rootState, rootGetters}, Data) {
        let URL = '';
        if (rootGetters["UserAuth/isLoggedIn"]) {
            API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
            URL = '/user/shootingbook/approval/approval_set';
        } else {
            API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
            URL = '/club/shootingbook/approval/approval_set';
        }
        await API.post(URL, Data)
            .then(() => {
                dispatch('getShootingbookforApproval');
            })
            .catch(error => {
                handleRegistrationError(error)
            });

    },
    getTodayMember({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        API.get('/club/today/users')
            .then((resp) => {
                const data = resp.data
                commit('get_TodayMember', data)
            })
            .catch(error => {
                console.log(error);
            });
    },
    getTodaySA({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        API.get('/club/today/SA')
            .then((resp) => {
                const data = resp.data
                commit('get_TodaySA', data)
            })
            .catch(error => {
                console.log(error);
            });
    },
    getTodaySL({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        API.get('/club/today/SL')
            .then((resp) => {
                const data = resp.data
                commit('get_TodaySL', data)
            })
            .catch(error => {
                console.log(error);
            });
    },
    async end_other_aufsicht({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/today/end_other_aufsicht', Data)
            .then(() => {
                dispatch('getTodaySL');
                dispatch('getTodaySA');
            })
            .catch(error => {
                // console.log(error.response.data);
                swal.fire('Fehler', error.response.data, 'warning');

            });

    },
    getClubMember({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        API.get('/club/member')
            .then((resp) => {
                const data = resp.data
                commit('get_ClubMember', data)
            })
            .catch(error => {
                console.log(error);
            });
    },
    async setClubMemberAdmin({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/member/admin', Data)
            .then(() => {
                dispatch('getClubMember');
            })
            .catch(error => {
                console.log(error);
            });

    },
    async setClubMemberAufsicht({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/member/aufsicht', Data)
            .then(() => {
                dispatch('getClubMember');
            })
            .catch(error => {
                console.log(error);
            });
    },

    async setClubMemberMemberShip({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/member/membership', Data)
            .then(() => {
                dispatch('getClubMember');
            })
            .catch(error => {
                console.log(error);
            });
    },


    async getVerband({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.get('/club/verband')
            .then((resp) => {
                const VerbandOwn = resp.data.VerbandOwn
                const VerbandAll = resp.data.VerbandAll
                commit('get_Verband', {VerbandAll, VerbandOwn})
            })
            .catch(error => {
                console.log(error);
            });
    },

    async setVerband({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/verband/set', Data)
            .then(() => {
                dispatch('getVerband');
            })
            .catch(error => {
                handleRegistrationError(error)
            });

    },
    async getRanges({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.get('/club/shootingrange')
            .then((resp) => {
                const data = resp.data
                commit('get_shootingrange', data)
            })
            .catch(error => {
                console.log(error);
            });
    },
    async setShootingRange({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/shootingrange', Data)
            .then(() => {
                dispatch('getRanges');
            })
            .catch(error => {
                handleRegistrationError(error)
            });

    },
    async updateShootingRange({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/shootingrange/update', Data)
            .then(() => {
                dispatch('getRanges');
            })
            .catch(error => {
                handleRegistrationError(error)
            });

    },

    async delShootingRange({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/shootingrange/del', Data)
            .then(() => {
                dispatch('getRanges');
            })
            .catch(error => {
                // console.log(error.response.data);
                swal.fire('Fehler', error.response.data, 'warning');

            });

    },
    async getWeapons({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.get('/club/weapons')
            .then((resp) => {
                const data = resp.data
                commit('get_Weapons', data)
            })
            .catch(error => {
                console.log(error);
            });
    },
    async setNewWeapon({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/weapons/weapon_new', Data)
            .then(() => {
                dispatch('getWeapons');
            })
            .catch(error => {
                handleRegistrationError(error)
            });
    },
    async delWeapon({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.get('/club/weapons/weapon_del', {
            params: Data
        })
            .then((resp) => {
                dispatch('getWeapons');
                swal.fire('Waffe löschen', resp.data, 'success');
            })
            .catch(error => {
                console.log(error.response);
                swal.fire('Waffe löschen', 'Es ist etwas schiefgelaufen beim löschen', 'warning');
            });
    },

    async getTerminals({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.get('/club/passport')
            .then((resp) => {
                const Terminals = resp.data
                commit('get_Terminals', Terminals)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async setNewTerminal({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/passport/new_client', Data)
            .then((resp) => {
                const secret = resp.data.client_secret
                swal.fire('Terminal erfolgreich angelegt', "Bitte folgenden Secret speicher: <br><br> " + secret + "<br><br><b>Er wird nicht mehr angezeigt!</b>", 'success');
                dispatch('getTerminals');
            })
            .catch(error => {
               handleRegistrationError(error)
            });
    },

    async updateTerminal({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/passport/update_client', Data)
            .then((resp) => {
                console.log(resp.data);
                dispatch('getTerminals');
            })
            .catch(error => {
                handleRegistrationError(error)
            });
    },

    async setDelTerminal({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/passport/delete_client', Data)
            .then(() => {
                dispatch('getTerminals');
                swal.fire('Terminal gelöscht', "Terminal erfolgreich gelöscht", 'warning');
            })
            .catch(error => {
                console.log(error.response);
            });
    },

    async setCreateNewMember({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/member/new_single_member', Data)
            .then(() => {
                dispatch('getClubMember');
                swal.fire('Neuer Benutzer', "Neuer Benutzer erfolgreich angelegt", 'success');
            })
            .catch(error => {
                const nachricht = error.response.data.message
                swal.fire('Neuer Benutzer', "Fehler beim anlegen des Benutzer <br><br>" + nachricht, 'error');
                dispatch('getClubMember');
                // console.log(nachricht);
            });
    },

    async setMemberRFIDToken({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/member/rfid_token', Data)
            .then(() => {
                dispatch('getClubMember');
                swal.fire('RFID Chip', "Dem Benutzer wurde ein RFID Chip zugewiesen", 'success');
            })
            .catch(error => {
                const nachricht = error.response.data
                swal.fire('RFID Chip', "Fehler beim anlegen des RFID Chips <br><br>" + nachricht, 'error');
                // console.log(nachricht);
            });
    },

    async getWorkingHours({commit, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.get('/club/arbeitsstunden/get', {
            params: Data
        })
            .then((resp) => {
                const data = resp.data;
                commit('set_WorkingHours', data);
            })
            .catch(error => {
                const nachricht = error.response.data.message
                swal.fire('Arbeitsstunden', nachricht, 'error');
            });
    },


};

export default actions;
